import React, {useState } from "react"
import {Link} from "gatsby"
import {graphql} from "gatsby"
import Img from "gatsby-image"
import {Container, Row, Col} from 'react-bootstrap'
import Homelayout from "../components/homelayout"
import SEO from "../components/seo"
import Carousel from 'react-bootstrap/Carousel'

import BtnA from "../images/btn_a.inline.svg"

import Ecommerce from "../images/homeicons/E-commerce_integration.inline.svg"
import Email from "../images/homeicons/Email_invoicing.inline.svg"
import Merchant from "../images/homeicons/merchant_finder.inline.svg"
import Plugins from "../images/homeicons/Plugins.inline.svg"
import Pos from "../images/homeicons/POS.inline.svg"
import Reporting from "../images/homeicons/Reporting.inline.svg"
import Transaction from "../images/homeicons/Transaction_overview.inline.svg"
import Usermanagement from "../images/homeicons/user_management.inline.svg"

import Superfast from "../images/homeicons/superfast.inline.svg"
import Futureproof from "../images/homeicons/futureproof.inline.svg"
import Low from "../images/homeicons/low.inline.svg"

import Illustrationf from "../images/homeicons/Illustration_01.inline.svg"
import Illustrations from "../images/homeicons/Illustration_02.inline.svg"
import Illustrationt from "../images/homeicons/Illustration_03.inline.svg"
import Calculator from "../components/calculator"
import useScrollPosition from '@react-hook/window-scroll'


import ArrowN from "../images/Arrow_next.inline.svg"



function ScrollView({children, ...props}) {

  
  return (
      <div {...props}>
        <div style={{
          position: 'relative',
          height: '100%',
          width: '100%',
          overflow: 'hidden auto'
        }}
        decelerationRate={0.9}>
          <div>
            {children}
          </div>
        </div>
      </div>
  )
}

function IndexPage({data, pageContext: {lang, currentPath}}) {

  const [activeSlide, setActiveSlide] = useState(0)




  return (
    <Homelayout>
      <SEO title="Home"/>

      <Container className="home-above">

        <Carousel
          fade={true}
          slide={false}
          speed={5}
          autoplay={false}
          // interval={null}
          activeIndex={activeSlide} onSelect={setActiveSlide}
>
          <Carousel.Item>
            <Row>
              <Col className="home-above-text">
                <h1>Accept Dagcoin payments, <strong>effortlessly.</strong></h1>
                <p>Become part of a growing community of more than 500,000 users</p>
                <a href="https://test-app.dagpay.io/sign-up/" className="gray">Sign up now<BtnA/></a>
              </Col>
              <Col>
                <div className="bd-example">
                  <Img fluid={data.slideone.childImageSharp.fluid}/>
                </div>
              </Col>
            </Row>
          </Carousel.Item>

          <Carousel.Item>
            <Row>
              <Col className="home-above-text">
                <h1><strong>Get paid faster </strong> by accepting dagcoin payments.</h1>
                <p>Start accepting dagcoins and receive payments from your clients almost instantly.</p>
                <a href="https://test-app.dagpay.io/sign-up/" className="gray">Sign up now<BtnA/></a>
              </Col>
              <Col>
                <div className="bd-example">
                <Img fluid={data.slidethree.childImageSharp.fluid}/>

                </div>
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item>
            <Row>
              <Col className="home-above-text">
              <h1><strong>Simple, yet effective</strong> solution for online shops.</h1>
                <p>Easily integrable plugins for popular eCommerce platforms and robust API for receiving payments swiftly online</p>
                <a href="https://test-app.dagpay.io/sign-up/" className="gray">Sign up now<BtnA/></a>
              </Col>
              <Col>
                <div className="bd-example">
                <Img fluid={data.slidefour.childImageSharp.fluid}/>
                </div>
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item>
            <Row>
              <Col className="home-above-text">
                <h1>Dagcoin POS system businesses <strong>love to use.</strong></h1>
                <p>Accept dagcoin payments face-to-face at your shop, restaurant, etc.</p>
                <a href="https://test-app.dagpay.io/sign-up/" className="gray">Sign up now<BtnA/></a>
              </Col>
              <Col>
                <div className="bd-example">
                <Img fluid={data.slidetwo.childImageSharp.fluid}/>
                </div>
              </Col>
            </Row>
          </Carousel.Item>

        </Carousel>
       <div className="mobilebtn">
         <a href="https://test-app.dagpay.io/sign-up/" className="green">Sign up now<BtnA/></a>
         </div>
      </Container>
      <Container className="after-above-home">
        <Row>
          <Col className={activeSlide === 1 ? "active" : ""}>
            <Link to="#" onClick={(e) => {e.preventDefault(); setActiveSlide(1)}}>
              <h4>On website</h4>
              <p>Simple checkout system with plugins</p>
              <Link to="/on_website/" className="readmoreheader">Learn more <ArrowN/></Link>

            </Link>
          </Col>
          <Col className={activeSlide === 2 ? "active" : ""}>
            <Link to="#" onClick={(e) => {e.preventDefault(); setActiveSlide(2)}}>
              <h4>In person</h4>
              <p>A simple and efficient point of sale (POS) system</p>
              <Link to="/in_person/" className="readmoreheader">Learn more <ArrowN/></Link>

            </Link>
          </Col>
          <Col className={activeSlide === 3 ? "active" : ""}>
            <Link to="#" onClick={(e) => {e.preventDefault(); setActiveSlide(3)}}>
              <h4>By email</h4>
              <p>Create email invoices for your customers</p>
              <Link to="/by_email/" className="readmoreheader">Learn more <ArrowN/></Link>

            </Link>
          </Col>
        </Row>
      </Container>
      <Container id="paymentsystem" className="fullwidthcontainer whitebg">
        <Row className="paymentabove"><h2><strong>Efficient</strong> and <strong>powerful</strong> payment
          system</h2></Row>
        <Row className="paymentimage hide-mobile"><Img fluid={data.homepage.childImageSharp.fluid}/></Row>
        <Row className="paymentimage show-mobile"><Img fluid={data.homepagem.childImageSharp.fluid}/></Row>
        <Row className="paymenticons">
          <Col>
            <h4>User management</h4>
            <Usermanagement/>
          </Col>
          <Col>
            <h4>POS checkout</h4>
            <Pos/>
          </Col>
          <Col>
            <h4>E-commerce integration</h4>
            <Ecommerce/>
          </Col>
          <Col>
            <h4>Plugins</h4>
            <Plugins/>
          </Col>
        </Row>
        <Row className="paymenticons">
          <Col>
            <h4>Transaction overview</h4>
            <Transaction/>
          </Col>
          <Col>
            <h4>Merchant finder</h4>
            <Merchant/>
          </Col>
          <Col>
            <h4>Email invoicing</h4>
            <Email/>
          </Col>
          <Col>
            <h4>Reporting</h4>
            <Reporting/>
          </Col>
        </Row>
        <Row className="paymentcut">
          <p>A payment solution with cutting edge features developed to make your life easier.</p>
          <a href="https://test-app.dagpay.io/sign-up/" className="green">Sign up now<BtnA/></a>
        </Row>
        <Row className="acceptcoins">
          <Col>
            <h4><strong>Best way</strong> to accept Dagcoin payments</h4>
            <p>Dagpay allows you to accept dagcoins from anywhere in the world in seconds.</p>
            <a href="https://dagcoin.org" className="darkbtn">Find out more about Dagcoin<BtnA/></a>
          </Col>
          <Col className="acceptsecond">
            <ul>
              <li>
                <h5><Superfast/>Super fast payments</h5>
                <p>Dagcoin payments take just 30 seconds to reach your wallet.</p>
              </li>
              <li>
                <h5><Low/>Low-cost transactions</h5>
                <p>The transaction cost is only 0.0005 DAG no matter the amount.</p>
              </li>
              <li>
                <h5><Futureproof/>Futureproof</h5>
                <p>The more users Dagcoin has, the faster the payments become.</p>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
      <Container id="darkhomeslider" className="darkhomeslide fullwidthcontainer">
        <Row>
          <Col>
          <div className="darkhs">
            <h2>How does <strong>Dagpay</strong> work?</h2>
            <p>A fast and safe way to receive payments.</p>
            <a href="https://test-app.dagpay.io/sign-up/" className="blueb">Sign up now<BtnA/></a>
          </div>
          </Col>
          <ScrollView className="scrollview">
            <Col className="carousel">

              <div className="slide">
                <div className="carousel-content">
                  <div>
                    <p><strong>Customer</strong></p>
                    <p>Chooses to pay to dagcoins</p>
                  </div>
                  <div>
                    <Illustrationf/>
                  </div>
                </div>
              </div>

              <div className="slide">
                <div className="carousel-content">
                  <div>
                    <p><strong>Dagpay</strong></p>
                    <p>Creates and invoice with a unique code for the customer and provides payment
                      information to the merchant.</p>
                  </div>
                  <div>
                    <Illustrations/>
                  </div>
                </div>
              </div>

              <div className="slide">
                <div className="carousel-content">
                  <div>
                    <p><strong>Merchant</strong></p>
                    <p>Customer pays directly to your Dagcoin wallet.</p>
                  </div>
                  <div>
                    <Illustrationt/>
                  </div>
                </div>
              </div>

            </Col>
          </ScrollView>

          <div className="mobileonly">
            <p>A fast and safe way to receive payments.</p>
            <a href="https://test-app.dagpay.io/sign-up/" className="blueb">Sign up now<BtnA/></a>
          </div>
        </Row>
      </Container>
      <Calculator/>
    </Homelayout>
  )
}

export const query = graphql`
    query {
        slideone: file(relativePath: { eq: "slide1.png" }) {
            childImageSharp {
                fluid(maxWidth: 3000, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        homepage: file(relativePath: { eq: "homepage.png" }) {
            childImageSharp {
                fluid(maxWidth: 3000, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        homepagem: file(relativePath: { eq: "homepagem.png" }) {
          childImageSharp {
              fluid(maxWidth: 3000, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
              }
          }
      }
        slidetwo: file(relativePath: { eq: "slide2.png" }) {
            childImageSharp {
                fluid(maxWidth: 3000, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        slidethree: file(relativePath: { eq: "slide3.png" }) {
            childImageSharp {
                fluid(maxWidth: 3000, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        slidefour: file(relativePath: { eq: "slide4.png" }) {
            childImageSharp {
                fluid(maxWidth: 3000, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`
export default IndexPage
