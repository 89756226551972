import React, {useState} from 'react'
import {Container, Row} from "react-bootstrap";
import ReactSlider from 'react-slider'

import './calculator.scss';

const Calculator = props => {

    const [value, setValue] = useState(250);

    return <div id="pricing"><Container className="fullwidthcontainer whitebg calculator">
        <Container>
            <Row>
                <div className="textarea-calc">
                <h3>Save <strong>more</strong></h3>
                <p>Regular forms of payment come with high charges and hidden fees. Not Dagpay.</p>
                </div>               
                <div className="calculator__range-slider">
                    <ReactSlider
                        value={value}
                        onChange={setValue}
                        min={1}
                        max={10000}
                        className="range-slider"
                        thumbClassName="range-slider__thumb"
                        trackClassName="range-slider__track"
                        renderThumb={(props, state) => <div {...props}>
                            <div className="callout">
                                $&nbsp;{state.valueNow}.00
                            </div>
                        </div>}
                    />
                </div>

                <div className="calculator__items">
                    <div className="calculator__item">
                        <h4>PAYPAL</h4>
                        <div className="value">
                            <span className="prefix">$</span>
                            {(value * 3.9 / 100 + 0.30).toFixed(2)}</div>
                        <p>3.9% + $0.30 per every transaction</p>
                    </div>

                    <div className="calculator__item secondcalc">
                        <h4>STRIPE</h4>
                        <div className="value">
                            <span className="prefix">$</span>
                            {(value * 2.9 / 100 + 0.30).toFixed(2)}</div>
                        <p>2.9% + $0.30 per every transaction</p>
                    </div>

                    <div className="calculator__item calculator__item--active">
                        <h4>DAGPAY</h4>
                        <div className="value">
                            <span className="prefix">$</span>
                            0.00</div>
                        <p>No fees*</p>

                        <a href="https://test-app.dagpay.io/sign-up/" className="calculator__btn">
                            Get started
                        </a>
                    </div>
                </div>
                <div className="calc_footer">
                    <p>0% is an introductory offer for the duration of our Beta development stage. Once this period is over a standard fee of 1% will be charged. All users will be informed long in advance of this change, which is due in 2022.</p>
                </div>
            </Row>
        </Container>

    </Container>


    </div>
}

export default Calculator